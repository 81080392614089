<template>
  <div class="header-contain">
    <div class="header-main">
      <div class="company-logo">
        <img alt="logo" src="./assets/logo.png" />
      </div>
      <div class="nav">
        <!-- <div class="shopping">商城
          <div class="shop-list">
            <ul>
              <li @click="toOutSide('https://www.1688.com')"><a href="javascript:void(0);" >1688旗舰店</a></li>
              <li @click="toOutSide('https://www.taobao.com')"><a href="javascript:void(0);" >淘宝旗舰店</a></li>
              <li @click="toOutSide('https://www.jd.com')"><a href="javascript:void(0);" >京东旗舰店</a></li>
            </ul>
          </div>
        </div> -->
        <a @click="totop('/')">首页</a>
        <a @click="totop('/yuancai')">原材溯源</a>
        <a @click="totop('/jiameng')">品牌加盟</a>
        <a @click="totop('/product')">精制美味</a>
      </div>
    </div>
  </div>
  <router-view />
  <div class="footer">
    <div class="footer-main">
      <img alt="qrcode" class="qrcode" src="./assets/qrcode.png" />
      <div class="text">
        <div class="item">
          <div class="label">服务热线：</div>
          <div>0516-88200788</div>
        </div>
        <div class="item">
          <div class="label">电子邮件：</div>
          <div>jig-ifl1ix9uh@dingtalk.com</div>
        </div>
        <div class="item">
          <div class="label">企业地址：</div>
          <div>江苏省徐州市睢宁县梁集镇中谷智造新城S2号厂房</div>
        </div>
      </div>
      <div class="text">
        <div class="item">
          <div class="label">版权所有：</div>
          <div>江苏岳艾生物科技有限公司</div>
        </div>
        <div class="item">
          <div class="label"></div>
          <div><a href="http://beian.miit.gov.cn" target="_blank">苏ICP备2024111014号-1</a></div>
        </div>
      </div>
    </div>
  </div>
  <div></div>
</template>
<script>

export default {
  methods: {
    totop(path) {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      location.hash = path;
    },toOutSide(url){
      window.open(url)
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f8f8f8;

}

html {
  font-size: 62.5%;
}

* {
  padding: 0;
  margin: 0;

}

::-webkit-scrollbar {
  width: 0;
}

/* @font-face{
  font-family: linhailishu;
  src: url(./font/linhailishu.ttf);
} */
/* 顶部导航栏 */
.header-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  box-shadow: 0 0 1rem 0 #2b2b2b22;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: #fff;
  z-index: 1000;
}

.header-main {
  width: 120rem;
  display: flex;
  justify-content: space-between;
}

.header-main .company-logo {
  font-size: 1.5rem;
  font-weight: 900;
  color: #ccc;
  display: flex;
  justify-content: right;

  height: 5rem;

}
.header-main .company-logo >img{
  width: 100%;
}

.header-main .nav {
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 1.5rem;
}

.header-main .nav>a {
  flex-basis: 10rem;
  text-decoration: none;
  color: #111;
  font-weight: 400;
  width: 10rem;
  cursor: pointer;
}

.header-main .nav>a:hover {
  color: #F3612B;
  font-weight: 700;
}


/* 底部 */
.footer {
  margin-top: 10rem;
  width: 100vw;
  background-color: #2c3e50;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
}

.footer-main {
  width: 100rem;
  display: flex;
  padding: 3rem 0;
  justify-content: center;
}

.footer-main>img {
  width: 10rem;
  height: 10rem;
  margin: 0 5rem;
}

.footer-main .text {
  color: #fff;
  flex: 2;
  line-height: 3rem;
}

.footer-main .text .item {
  display: flex;
}

.footer-main .text .item a {
  text-decoration: none;
  color: #fff;
}

.shop-list {
  padding-top: 2.5rem;
  display: none;
  position: absolute;
  margin-top: -1rem;
  margin-left: -1.5rem;
}

.shop-list>ul {

  top: 1.5rem;
  padding: 1rem 2rem;
  background-color: #F3612B;
  list-style: none;
  color: #fff;
  border-radius: 0.5rem;
}

.shop-list li {
  font-weight: 400;
  line-height: 3.5rem;
  border-top: #fff 1px solid;
  
  padding: 0 0.5rem;
}
.shop-list li a{
  text-decoration: none;
  color: #fff;
}
.shop-list li:hover {
  background-color: #fff;
  border-radius: 0.5rem;
}

.shop-list li:hover a{
  color: #2c3e50
}

.shop-list li:nth-child(1) {
  border: none;
}

.shopping:hover {
  background-color: #fff;
  color: #F3612B !important;
  cursor: pointer;
}

.shopping:hover>.shop-list {
  display: block;
}

.shop-list:hover {
  display: block;
}

.shopping {
  border: #F3612B 1px solid;
  line-height: 3rem;
  background-color: #F3612B;
  border-radius: 0.5rem;
  width: 10rem;
  color: #fff !important;
  font-weight: 600 !important;
}
</style>
