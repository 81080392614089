<template>
  <img class="top-bg" alt="首页标题图片" mode="top" src="../assets/top-bg.jpg">
  <div class="home-contain">
    <div class="home-main">
      <div class="home-item company-discription">
        <div class="item-title">品牌简介</div>
        <div class="discription">
          <div>
            <p>
              十万级无尘车间标准,严格执行车间进出管理制度。车间作业专属无尘服装和鞋套，经过风淋系统除尘,避免非授权人员进入无尘车间,从而保证食品的卫生和健康。原料选择上,严格遵守国家食品安全法律法规,选择具有GMP(Good
              Manufacturing Practice良好生产规范)认证的原料供应商。原料存储的温度、湿度、时间严格按照食品卫生标准,原料按生产日期、批次存储。拥有全自动蔬菜清洗机,高温消毒杀菌,保证原料的干净卫生，真正做到,让百姓吃到绿色食品,健康食品。
            </p>
            <p>
              “德艾缘春”属于江苏岳艾生物科技有限公司旗下,属于新型的快节奏早餐店模式,取代了传统早餐的早起、人工制作和卫生不达标等问题。我们的模式是出餐快速、无需大厨、品类多样、经营简单、干净卫生,是一个人就可以经营的早餐店。
            </p>
          </div>
          <div class="imgs">
            <div>
              <img  alt="tp" src="../assets/Image-8.png">
              <div>十万无尘车间</div>
            </div>
            <div>
              <img alt="tp" src="../assets/Image-9.png">
              <div>无尘产品化实验室</div>
            </div>
            <div>
              <img alt="tp" src="../assets/Image-10.png">
              <div>零下40°杀菌</div>
            </div>
            <div>
              <img alt="tp" src="../assets/Image-11.png">
              <div>无尘全自动生产线</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div>
            <img alt="tp" src="../assets/Image-6.png" />
          </div>
          <div>
            <p>占地面积<strong>9000平方米</strong>拥有三包立式和面机<strong>8台</strong>包子饺子生产线<strong>6套</strong></p>
            <p>醒蒸一体线<strong>6套</strong>摆放机<strong>6台</strong></p>
            <p>经过和面、发酵、醒蒸等工艺,形成年产<strong>4000吨</strong>食品加工规模</p>
          </div>
        </div>
        <div class="row">
          <div>
            <p>集研发、生产、销售、为一体</p>
            <p>专注于<strong>包子、水饺、馄饨、烧麦、豆浆、营养粥</strong>的生产</p>
            <p>拥有一座高度的现代化自动化生产工厂</p>
          </div>
          <div>
            <img alt="tp" src="../assets/Image-7.png" />
          </div>
        </div>

      </div>
      <div class="home-item ">
        <div class="item-title">品牌文化</div>
        <div class="qywh">
          <div class="wh-item">
            <img alt="tp" src="../assets/cx.jpg" />
            <p class="text">诚信</p>
            <p>诚信 - 做人</p>
          </div>
          <div class="wh-item">
            <img alt="tp" src="../assets/qm.jpg" />
            <p class="text">勤敏</p>
            <p>勤敏 - 做事</p>
          </div>
          <div class="wh-item">
            <img alt="tp" src="../assets/qx.jpg" />
            <p class="text">谦学</p>
            <p>谦学 - 立身</p>
          </div>
          <div class="wh-item">
            <img alt="tp" src="../assets/jy.jpg" />
            <p class="text">艰毅</p>
            <p>艰毅 - 立业</p>
          </div>
        </div>
      </div>
      <div class="home-item ">
              <img alt="tp" mode="top" style="width: 100%;" src="../assets/bt.png"/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {

  }
}
</script>
<style>
.top-bg {
  width: 100vw;
  height: auto;
}

.home-contain {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.home-contain .home-main {
  width: 120rem;
}
.home-main .home-item {
  margin-top: 8rem;
}
.home-main .home-item .item-title {
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 5rem;
  height: 8rem;
  margin: 5rem 0 1rem 0;
}

.home-main .home-item .item-title::before {
  content: "";
  position: absolute;
  border-bottom: 0.2rem solid #F3612B;
  width: 4rem;
}

.home-main .home-item .item-title::after {
  content: "";
  position: absolute;
  border-bottom: 0.4rem solid #F3612B;
  width: 8rem;
  margin-top: 5rem;
  margin-left: -8rem;
}

.company-discription .discription {
  text-align: left;
  line-height: 3rem;
  display: flex;
  align-items: center;
}

.company-discription .discription>div {
  display: flex;
  font-size: 1.6rem;
  line-height: 3.3rem;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 50%;
}

.company-discription .discription>div>p {
  text-indent: 4rem;
}

.company-discription .discription>div>div {
  width: 50%;
  justify-content: space-around;
  margin-top: 1rem;
}

.company-discription .discription>div>div>img {
  width: 95%;
  border-radius: 0.5rem;
}

.company-discription .discription>div>div>div {
  width: 95%;
  background-color: #0007;
  position: relative;
  margin-top: -4.3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
}

.company-discription .row {
  display: flex;
  margin-top: 8rem;
  align-items: center;
}

.company-discription .row>div {
  width: 50%;
  text-align: center;
}

.company-discription .row>div>p {
  font-size: 1.6rem;
  height: 5rem;
  line-height: 5rem;
}

.company-discription .row>div>p>strong {
  font-size: 2.5rem;
  color: #FBB03F;
  font-weight: 400;
}

.company-discription .row img {
  width: 95%;
  border-radius: 0.5rem;
}


.qywh {
  width: 100%;
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 2rem;
}

.qywh .wh-item {

  width: 46%;
  margin: 2rem 0;
  background-color: #fff;
  box-sizing: border-box;
  position: relative;
  height: fit-content;
  overflow: hidden;
  border-radius: 0.5rem;

}

.qywh .wh-item img {
  width: 100%;
  z-index: 1;
}
.qywh .wh-item p{
  margin: 1rem 0;
  font-size: 1.4rem;
}
.qywh .wh-item .text {
  font-size: 3rem;
  margin: 0.5rem 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  position: relative;
  color: #f58114;
}


.qywh .wh-item:hover {
  background-color: #f58114;
  p {color: #fff;}
  background-color: f58114;
  transform: scale(1.05);
  z-index: 100;
  transition: all  0.7s;
}
</style>
